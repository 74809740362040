import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    InputBase,
    TextField,
    Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import {convertStringToMoney, convertToCurrency} from "./utils/Utils";
import DeleteIcon from '@mui/icons-material/Delete';
import {useEffect, useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import './css/PurchaseItem.css';

export default function PaymentTile({id, paymentMethod, amount, deletePayment, showDeletePayment}) {

    return (
        // <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ bgcolor: 'orange', height: '48px', padding: '6px', margin: '8px', display: 'flex' }}>
        <Box sx={{ marginBottom: '6px', minWidth: '320px' }}>
                <Card sx={{ padding: '16px', display: 'flex', flexDirection: 'row' }} >
                    {showDeletePayment && <Button onClick={deletePayment} startIcon={<CloseIcon sx={{ marginRight: '0px', marginLeft: '0px' }}/>} variant="contained" color="error" sx={{ display: 'flex', maxWidth: '5px', width: '5px', marginLeft: '-10px', marginRight: '8px', minWidth: '32px', paddingRight: '3px', borderRadius: '32px' }} />}
                    <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1' }}>
                        <Typography variant="h6" sx={{ flex: 1, fontWeight: 'bold' }}>{paymentMethod}</Typography>
                        <Box sx={{ flex: '1' }}/>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{convertStringToMoney(amount)}</Typography>
                    </Box>
                </Card>
        </Box>
    )
}