import axios from 'axios';
import axiosRetry from 'axios-retry';
import userpool from "../auth/Userpool";


const BASE_URL = 'https://8lc7uqjk4j.execute-api.ap-southeast-2.amazonaws.com/dev/category'

axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 100; // time interval between retries
    },
});

export function listCategories() {
    return axios.get(BASE_URL, {
        headers: {
            'Authorization': `Bearer ${getCurrentUserSessionIdToken()}`
        }
    })
        .then(response => response.data);
}

export function createCategory(name, color) {
    return axios.post(`${BASE_URL}`, {
        name,
        color
    }, {
        headers: {
            'Authorization': `Bearer ${getCurrentUserSessionIdToken()}`
        }
    })
        .then(response => response.data);
}

export function updateCategory(id, name, color) {
    return axios.post(`${BASE_URL}/${id}`, {
        name,
        color
    }, {
        headers: {
            'Authorization': `Bearer ${getCurrentUserSessionIdToken()}`
        }
    })
        .then(response => response.data);
}

export function deleteCategory(id) {
    return axios.delete(`${BASE_URL}/${id}`, {
        headers: {
            'Authorization': `Bearer ${getCurrentUserSessionIdToken()}`
        }
    })
        .then(response => response.data);
}

function getCurrentUserSessionIdToken() {
    return userpool.getCurrentUser().getSession((error, session) => {
        return session.getIdToken().getJwtToken();
    });
}