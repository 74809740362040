import { useEffect } from 'react';

export function convertToCurrency(amount) {
    if (typeof amount === 'string' || amount instanceof String) {
        amount = +amount;
    }
    return amount.toFixed(2);
}

export function convertStringToMoney(amount) {
    return '$' + Number(amount).toLocaleString(undefined, {minimumFractionDigits: 2});
}

export function useTriggerScrollFix(deps) {
    console.log(deps);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dispatchEvent(new CustomEvent('scroll'));
        }
    }, deps);
}
