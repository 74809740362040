import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {Link, Outlet, useLocation} from "react-router-dom";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {useEffect, useState} from "react";
import {logout} from "./auth/Authenticate";
import { listItems } from "./api/ItemApi";
import { listCategories } from "./api/CategoryApi";
import {Waveform} from "@uiball/loaders";
import {Auth} from "aws-amplify";
import {Alert, Backdrop, Snackbar} from "@mui/material";

import LogoutIcon from '@mui/icons-material/Logout';
import InventoryIcon from '@mui/icons-material/Inventory';
import ClassIcon from '@mui/icons-material/Class';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {listTransactions} from "./api/TransactionApi";
import SettingsIcon from '@mui/icons-material/Settings';
import {getConfiguration} from "./api/ConfigurationApi";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const menuItems = [
    {
        name: 'Point of Sales',
        icon: <PointOfSaleIcon/>,
        link: '/'
    },
    {
        name: 'Item',
        icon: <InventoryIcon/>,
        link: '/item'
    },
    {
        name: 'Category',
        icon: <ClassIcon/>,
        link: '/category'
    },
    {
        name: 'Sales History',
        icon: <AttachMoneyIcon/>,
        link: '/sales'
    },
    {
        name: 'Settings',
        icon: <SettingsIcon/>,
        link: '/settings'
    }
]

export const AppContext = React.createContext({})

export default function Application({userSession, setUserSession}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transactionMap, setTransactionMap] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [configuration, setConfiguration] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('Loading your data...');

    const currentLocation = useLocation();

    const selectedMenu = (itemLink) => {
        return currentLocation.pathname === itemLink;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const onClickLogout = () => {
        setUserSession(null);
        Auth.signOut().then();
    }

    const [categoryMap, setCategoryMap] = useState({});
    const [categoryNameMap, setCategoryNameMap] = useState({});
    const [itemMap, setItemMap] = useState({});
    const [itemSkuMap, setItemSkuMap] = useState({});
    const [itemNameMap, setItemNameMap] = useState({});
    const [categoryToItemMap, setCategoryToItemMap] = useState({});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {

        listItems()
            .then(itemData => {
                setItems(itemData.items);

                const map = {};
                const nameMap = {};
                const itemSkuMap = {};

                itemData.items.map(item => {
                    map[item.itemId] = {
                        ...item
                    }
                    nameMap[item.name] = {
                        ...item
                    }
                    itemSkuMap[item.sku] = {
                        ...item
                    }
                });

                setItemMap(map);
                setItemNameMap(nameMap);

                listCategories()
                    .then(data => {
                        setCategories(data.categories);

                        const map = {
                            'UNCATEGORISED': {
                                name: 'Uncategorised',
                                color: '#000000'
                            }
                        };
                        const nameMap = {
                            'UNCATEGORISED': {
                                name: 'Uncategorised',
                                color: '#000000'
                            }
                        };
                        data.categories.map(category => {
                            map[category.id] = {
                                ...category
                            }
                            nameMap[category.name] = {
                                ...category
                            }
                        });

                        setCategoryMap(map);
                        setCategoryNameMap(nameMap);

                        const categoryToItem = {};

                        itemData.items.forEach(item => {
                            categoryToItem[item.categoryId] = item;
                        })

                        setCategoryToItemMap(categoryToItem);

                        listTransactions()
                            .then(data => {
                                setTransactions(data);
                                const transactionMap = {};
                                data.transactions.forEach(transaction => {
                                    transactionMap[transaction.transactionId] = transaction;
                                })
                                setTransactionMap(transactionMap);

                                getConfiguration().then(data => {
                                    setConfiguration(Object.fromEntries(
                                        data.configuration
                                            .substring(1, data.configuration.length - 1)
                                            .split(', ')
                                            .map(pair => pair.split('='))
                                            .map(([key, value]) => [key, isNaN(value) ? value : +value])));
                                    setLoaded(true);
                                    setLoadingMessage('Loading your data...');
                                })
                            })
                    });
            });
    }

    return (
        <Box sx={{display: 'flex', height: '100%'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold' }}>
                        forefront
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {menuItems.map((item, index) => (
                        <ListItem key={item.name} disablePadding sx={{display: 'block'}}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                component={Link}
                                to={item.link}
                                selected={selectedMenu(item.link)}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <Divider/>
                    <ListItemButton sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                    onClick={() => onClickLogout()}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Log out" sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </List>

            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p: 3, height: '100%'}}>
                <DrawerHeader/>
                {
                    !loaded && <Box sx={{ display: 'flex', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={!loaded}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Waveform
                                        size={80}
                                        lineWeight={3.5}
                                        speed={1}
                                        color="white"
                                    />
                                </Box>
                                <p>{loadingMessage}</p>
                            </Box>
                        </Backdrop>
                    </Box>
                }
                {
                    loaded &&
                    <AppContext.Provider value={{ items: items, categories: categories, itemMap: itemMap, categoryMap: categoryMap, itemNameMap: itemNameMap, itemSkuMap: itemSkuMap, categoryNameMap: categoryNameMap, categoryToItemMap: categoryToItemMap, transactions: transactions, transactionMap: transactionMap, loadData: loadData, setAlertOpen: setAlertOpen, setAlertMessage: setAlertMessage, configuration: configuration, setLoaded: setLoaded, setLoadingMessage: setLoadingMessage }}>
                        <Outlet/>
                    </AppContext.Provider>
                }
            </Box>

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
                <Alert onClose={() => setAlertOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}