import {useContext, useEffect, useState} from "react";
import { Waveform } from '@uiball/loaders'

import {listItems} from "./api/ItemApi";
import {Box, Button, Grid, InputBase, MenuItem, Paper, Select, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {AppContext} from "./Application";
import AddItemForm from "./AddItemForm";
import CategoryCard from "./CategoryCard";
import AddCategoryForm from "./AddCategoryForm";
import SearchIcon from '@mui/icons-material/Search';
import * as React from "react";
import {createTransaction} from "./api/TransactionApi";
import {upsertConfiguration} from "./api/ConfigurationApi";


export default function Settings() {

    const props = useContext(AppContext);
    const configuration = props.configuration;

    console.log(configuration.itemSearchType);
    console.log(configuration.posSearchType);

    const [itemSearchType, setItemSearchType] = useState(configuration?.itemSearchType ? configuration.itemSearchType : 'NAME');
    const [posSearchType, setPosSearchType] = useState(configuration?.posSearchType ? configuration.posSearchType : 'NAME');

    console.log(itemSearchType);
    console.log(posSearchType);

    const handleSave = () => {
        props.setLoadingMessage('Saving your configuration...');
        props.setLoaded(false);

        upsertConfiguration({
            itemSearchType,
            posSearchType
        }).then(data => {
            props.loadData();
        });
    }

    const handleItemSearchTypeChange = (event) => {
        setItemSearchType(event.target.value);
    }

    const handlePosSearchTypeChange = (event) => {
        setPosSearchType(event.target.value);
    }

    return (
        <Box>
            <Typography variant="h3" noWrap component="div" sx={{paddingBottom: '16px'}}>
                Settings
            </Typography>
            <Divider variant="middle" sx={{marginBottom: '32px'}}/>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '48px', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ flex: 1 }}>
                        Default search type in POS screen
                    </Typography>
                    <Box sx={{ flex: 1 }}/>
                    <Select
                        value={posSearchType}
                        onChange={handlePosSearchTypeChange}
                        sx={{ flex: 1 }}
                    >
                        <MenuItem value={"NAME"}>Name</MenuItem>
                        <MenuItem value={"SKU"}>SKU</MenuItem>
                    </Select>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '48px', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ flex: 1 }}>
                        Default search type in Items screen
                    </Typography>
                    <Box sx={{ flex: 1 }}/>
                    <Select
                        value={itemSearchType}
                        onChange={handleItemSearchTypeChange}
                        sx={{ flex: 1 }}
                    >
                        <MenuItem value={"NAME"}>Name</MenuItem>
                        <MenuItem value={"SKU"}>SKU</MenuItem>
                    </Select>
                </Box>


                <Button variant="contained" color="primary" onClick={() => handleSave()}>
                    Save
                </Button>
            </Box>
        </Box>

    )
}