import {Box, Typography} from "@mui/material";
import * as React from "react";
import {convertStringToMoney, convertToCurrency} from "./utils/Utils";


export default function Prices({purchaseList, payments, countPayments}) {

    const calculateSubTotal = () => {
        let total = 0;
        Object.keys(purchaseList).forEach(key => {
            total += (purchaseList[key].price * purchaseList[key].quantity);
        })


        console.log(total);
        return convertToCurrency(total);
    }

    const calculateDiscount = () => {
        return convertToCurrency(0);
    }

    const calculateTax = (price) => {
        return price * 0.15;
    }

    const calculateTaxCurrency = (price) => {
        const tax = price * 0.15;
        return convertToCurrency(tax);
    }

    const calculateTotal = () => {
        const subPrice = calculateSubTotal() - calculateDiscount();
        let subTotal = subPrice + calculateTax(subPrice);


        if (countPayments && !!payments) {
            Object.keys(payments).forEach(key => {
                subTotal -= payments[key].amount;
            })
        }

        return convertToCurrency(subTotal);
    }

    return (
        <Box sx={{ flex: 1, paddingTop: '12px', margin: '8px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h6" sx={{ flex: 1 }}>Sub-total:</Typography>
                <Typography variant="h6">{convertStringToMoney(calculateSubTotal())}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h6" sx={{ flex: 1 }}>Discount:</Typography>
                <Typography variant="h6">{convertStringToMoney(calculateDiscount())}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h6" sx={{ flex: 1 }}>Tax:</Typography>
                <Typography variant="h6">{convertStringToMoney(calculateTaxCurrency(calculateSubTotal() - calculateDiscount()))}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="h6" sx={{ flex: 1, fontWeight: 'bold' }}>Total:</Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{convertStringToMoney(calculateTotal())}</Typography>
            </Box>
        </Box>

    );
}