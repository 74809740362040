import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Backdrop, Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useContext, useEffect} from "react";
import {AppContext} from "./Application";
import {useState} from "react";
import {Waveform} from "@uiball/loaders";
import {CirclePicker} from "react-color";
import {deleteCategory, updateCategory} from "./api/CategoryApi";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {deleteItem} from "./api/ItemApi";

export default function CategoryDialog({handleClose, open, id, name, color}) {

    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [newName, setNewName] = useState('');
    const [newColor, setNewColor] = useState('');
    const [categoryNameError, setCategoryNameError] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);

    const props = useContext(AppContext);

    const categoryNameMap = props.categoryNameMap;
    const categoryToItemMap = props.categoryToItemMap;

    useEffect(() => {
        setNewName(name);
        setNewColor(color);
    }, []);

    const isUpdateEnabled = () => {
        if (!newName || !newColor) {
            return false;
        }

        if (newName === name && newColor === color) {
            return false;
        }

        if (newName !== name && !!categoryNameMap[newName]) {
            return false;
        }

        return true;
    }

    const handleUpdate = () => {
        setLoading(true);

        updateCategory(id, newName, newColor)
            .then(result => {
                handleClose();
                setLoading(false);
                props.loadData();
            });
    };

    const onChangeName = (event) => {
        const newName = event.target.value;

        if (categoryNameMap[newName] && newName !== name) {
            setCategoryNameError('This category already exists');
        } else {
            setCategoryNameError('');
        }

        setNewName(newName);
    }

    const onChangeColor = (event) => {
        const newColor = event;
        setNewColor(newColor);
    }

    const handleDeleteDialog = () => {
        setDeleteOpen(true);
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    }

    const handleDelete = () => {
        setDeleteLoading(true);
        deleteCategory(id).then(result => {
            handleDeleteClose();
            handleClose();
            setDeleteLoading(false);
            props.loadData();
        });
    }

    const isDeleteIconEnabled = () => {
        return !!categoryToItemMap[id];
    }

    const deleteIconColor = () => {
        return !!categoryToItemMap[id] ? 'disabled' : 'error';
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
                open={loading}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Waveform
                            size={80}
                            lineWeight={3.5}
                            speed={1}
                            color="white"
                        />
                    </Box>
                    <p>Updating category...</p>
                </Box>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
                open={deleteLoading}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Waveform
                            size={80}
                            lineWeight={3.5}
                            speed={1}
                            color="white"
                        />
                    </Box>
                    <p>Deleting category...</p>
                </Box>
            </Backdrop>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Update Category
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField value={newName} onChange={(event) => onChangeName(event)} variant="outlined" label="Name" sx={{ flex: '1', marginTop: '24px' }} helperText={categoryNameError} error={!!categoryNameError}/>
                        <Box sx={{ flex: '1' }}>
                            <p>Color</p>
                            <Box sx={{ flexDirection: 'column' }}>
                                <CirclePicker
                                    color={newColor}
                                    onChange={(color) => onChangeColor(color.hex)}
                                />
                                <Box sx={{ flex: '1', backgroundColor: 'white' }}/>
                            </Box>

                        </Box>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={handleDeleteDialog} disabled={isDeleteIconEnabled()}>
                        <DeleteIcon color={deleteIconColor()}/>
                    </IconButton>
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleUpdate()} disabled={!isUpdateEnabled()}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteOpen}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Delete ${name}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}