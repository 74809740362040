import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Backdrop,
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    styled, Tooltip,
    Typography
} from "@mui/material";
import {useContext, useEffect} from "react";
import {AppContext} from "./Application";
import {createItem, deleteItem, updateItem} from "./api/ItemApi";
import {useState} from "react";
import {Waveform} from "@uiball/loaders";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import {canvasPreview} from "./canvasPreview";
import {useDebounceEffect} from "./useDebounceEffect";
import {useRef} from "react";
import {NumericFormat} from "react-number-format";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function ItemDialogue({handleClose, open, id, name, categoryName, sku, quantity, cost, price, markup}) {

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const hiddenAnchorRef = useRef(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1)

    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onSubmitCrop() {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size

        const blob = await offscreen.convertToBlob({
            type: 'image/jpeg',
            quality: 0.6
        });

        return await blobToBase64(blob);
    }

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )



    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [newName, setNewName] = useState('');
    const [newSku, setNewSku] = useState('');
    const [newQuantity, setNewQuantity] = useState("0");
    const [newCost, setNewCost] = useState("0.00");
    const [newPrice, setNewPrice] = useState("0.00");
    const [newMarkup, setNewMarkup] = useState("0");
    const [itemNameError, setItemNameError] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);

    const props = useContext(AppContext);

    const itemNameMap = props.itemNameMap;
    const categoryNameMap = props.categoryNameMap;

    useEffect(() => {
        setNewName(name);
        setNewSku(sku);
        setSelectedCategory(categoryName);
        setNewQuantity(quantity);
        setNewCost(cost);
        setNewPrice(price);
        setNewMarkup(markup);
    }, [open]);

    const isUpdateEnabled = () => {
        if (!newName || !selectedCategory || !newSku) {
            return false;
        }

        if (newName === name && selectedCategory === categoryName && newSku === sku && quantity === newQuantity && cost === newCost && price === newPrice && markup === newMarkup && !crop) {
            return false;
        }

        if (newName !== name && !!itemNameMap[newName]) {
            return false;
        }

        return true;
    }

    const handleUpdate = () => {
        setLoading(true);

        if (!!crop) {
            onSubmitCrop().then(image => {
                updateItem(id, newName, categoryNameMap[selectedCategory].id, newSku, image)
                    .then(result => {
                        handleClose();
                        setLoading(false);
                        props.loadData();
                    });
            })
        } else {
            console.log("Updating item")
            console.log(`cost: ${newCost}, price: ${newPrice}, markup: ${newMarkup}`)
            updateItem(id, newName, categoryNameMap[selectedCategory] ? categoryNameMap[selectedCategory].id : 'UNCATEGORISED', newSku, null, newQuantity, newCost, newPrice, newMarkup)
                .then(result => {
                    handleClose();
                    setLoading(false);
                    props.loadData();
                });
        }

    };

    const onChangeName = (event) => {
        const newName = event.target.value;

        if (itemNameMap[newName] && newName !== name) {
            setItemNameError('This item already exists');
        } else {
            setItemNameError('');
        }

        setNewName(newName);
    }

    const onSkuChange = (event) => {
        const newSku = event.target.value;
        setNewSku(newSku);
    }

    const onCategoryChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
    }

    const onChangeCost = (event) => {
        console.log("Updating cost");
        let newCost2 = event.value;
        console.log(`existing cost: ${newCost}`);
        console.log(`new cost: ${newCost2}`);

        if (newCost == newCost2) {
            console.log("Same cost, short circuiting");
            return;
        }

        if (!!!newCost2 || newCost2 === 0) {
            console.log("ZERO")
            newCost2 = "0.00";
        }

        console.log(event);

        setNewCost(newCost2);
        setNewPrice((((newMarkup / 100) + 1) *  newCost2).toFixed(2));
    }

    const onChangePrice = (event) => {
        console.log("Updating price")
        let newPrice2 = event.value;
        console.log(`existing price: ${newPrice}`);
        console.log(`new price: ${newPrice2}`);

        if (newPrice == newPrice2) {
            console.log("Same price, short circuiting");
            return;
        }

        if (!!!newPrice2 || newPrice2 == 0) {
            console.log("ZERO")
            newPrice2 = "0.00";
        }

        console.log(event);

        setNewPrice(newPrice2);
        setNewMarkup(((newCost === 0 ? 0 : (newPrice2 / newCost) - 1) * 100).toFixed(0));
    }

    const onChangeMarkup = (event) => {
        console.log(`Updating Markup`)
        let newMarkup2 = event.value;
        console.log(`existing markup: ${newMarkup}`);
        console.log(`new markup: ${newMarkup2}`);

        if (newMarkup == newMarkup2) {
            console.log("Same markup, short circuiting");
            return;
        }


        if (!!!newMarkup2) {
            console.log("ZERO")
            newMarkup2 = "0";
        }

        console.log(event);

        let newPrice = parseFloat(((newMarkup2 / 100) + 1) * newCost).toFixed(2);

        setNewMarkup(newMarkup2);
        setNewPrice(newPrice);
    }

    const onChangeQuantity = (event) => {
        let newQuantity = event.value;
        if (!!!newQuantity) {
            console.log("ZERO")
            newQuantity = "0";
        }

        console.log("SETTING!");

        setNewQuantity(newQuantity);
    }

    const categories = () => {
        return props.categories.sort((a, b) => a.name.localeCompare(b.name)).map(category => {
            return <MenuItem
                key={category.id}
                value={category.name}
                sx={{ color: category.color }}
            >
                {category.name}
            </MenuItem>
        });
    }

    const handleDeleteDialog = () => {
        setDeleteOpen(true);
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    }

    const handleDelete = () => {
        setDeleteLoading(true);
        deleteItem(id).then(result => {
            handleDeleteClose();
            handleClose();
            setDeleteLoading(false);
            props.loadData();
        });
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
                open={loading}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Waveform
                            size={80}
                            lineWeight={3.5}
                            speed={1}
                            color="white"
                        />
                    </Box>
                    <p>Updating item...</p>
                </Box>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
                open={deleteLoading}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Waveform
                            size={80}
                            lineWeight={3.5}
                            speed={1}
                            color="white"
                        />
                    </Box>
                    <p>Deleting item...</p>
                </Box>
            </Backdrop>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Update Item
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField value={newName} onChange={(event) => onChangeName(event)} variant="outlined" label="Name" sx={{ flex: '1', margin: '16px', marginTop: '24px' }} helperText={itemNameError} error={!!itemNameError}/>
                        <Select labelId="category-label" label="Category" displayEmpty value={selectedCategory} onChange={(category) => onCategoryChange(category)} sx={{ flex: '1', margin: '16px' }}>
                            {categories()}
                        </Select>
                        <InputLabel id="category-label" sx={{ marginTop: !itemNameError ? '112px' : '136px', marginLeft: '16px' }}>Category</InputLabel>

                        <NumericFormat customInput={TextField}
                                       variant="outlined"
                                       value={newCost}
                                       label="Cost"
                                       thousandSeparator={true}
                                       placeholder={"0.00"}
                                       decimalScale={2}
                                       allowNegative={false}
                                       fixedDecimalScale
                            // onChange={handleChange}
                                       valueIsNumericString={true}
                                       defaultValue={cost}
                                       autoComplete="off"
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                                       onValueChange={(event) => onChangeCost(event)}
                            // isAllowed={(values) => {
                            //     const {floatValue} = values;
                            //     console.log(floatValue);
                            //     return floatValue >= 0;
                            // }}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />

                        <NumericFormat customInput={TextField}
                                       variant="outlined"
                                       value={newPrice}
                                       label="Price"
                                       thousandSeparator={true}
                                       decimalScale={2}
                                       defaultValue={price}
                                       allowNegative={false}
                                       fixedDecimalScale
                            // onChange={handleChange}
                                       placeholder={"0.00"}
                                       autoComplete="off"
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                                       onValueChange={(event) => onChangePrice(event)}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />

                        <NumericFormat customInput={TextField}
                                       variant="outlined"
                                       value={newMarkup}
                                       label={
                                           <Tooltip title="The markup is what you want to add on top of the Cost of the item, calculated as (Cost x Markup) + Cost = Retail Price
- e.g. You've set a markup at 20% for a $10 item this would give
($10 x 20%) + $10 = $2 + $10 = $12">
                                               Markup
                                               <IconButton>
                                                   <HelpOutlineIcon />
                                               </IconButton>
                                           </Tooltip>
                                       }
                                       thousandSeparator={true}
                                       decimalScale={0}
                                       defaultValue={markup}
                                       allowNegative={true}
                                       fixedDecimalScale
                            // onChange={handleChange}
                                       autoComplete="off"
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                       }}
                                       placeholder={"0"}
                                       onValueChange={(event) => onChangeMarkup(event)}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />

                        <NumericFormat customInput={TextField}
                                       variant="outlined"
                                       value={newQuantity}
                                       defaultValue={quantity}
                                       label="Quantity"
                                       thousandSeparator={true}
                                       decimalScale={0}
                                       allowNegative={false}
                                       fixedDecimalScale
                            // onChange={handleChange}
                                       autoComplete="off"
                                       placeholder={"0"}
                                       onValueChange={(event) => onChangeQuantity(event)}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />


                        <TextField value={newSku} onChange={(event) => onSkuChange(event)} variant="outlined" label="SKU" sx={{ flex: '1', margin: '16px' }}/>
                        {!!imgSrc && (
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                                maxHeight={512}
                                keepSelection={true}
                                locked={true}
                                style={{maxWidth: '90%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}
                        {!!completedCrop && (
                            <>
                                <div style={{display: 'none'}}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        <Button variant="outlined" startIcon={<CameraAltIcon />} sx={{ margin: '16px' }} type="file" accept="image/*" component="label">
                            Upload Image
                            <VisuallyHiddenInput type="file" accept="image/*" onChange={onSelectFile}/>
                        </Button>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={handleDeleteDialog}>
                        <DeleteIcon color='error'/>
                    </IconButton>
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleUpdate()} disabled={!isUpdateEnabled()}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                    open={deleteOpen}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Delete ${name}?`}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}