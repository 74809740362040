import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    InputBase,
    TextField,
    Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import {convertStringToMoney, convertToCurrency} from "./utils/Utils";
import DeleteIcon from '@mui/icons-material/Delete';
import {useEffect, useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import './css/PurchaseItem.css';

export default function PurchaseItem({purchase, decreasePurchase, setPurchase, showDeleteButton, showExpand}) {

    const getQuantityText = () => {
        return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{purchase.quantity}</Typography>
            <Typography variant="h6">&nbsp;x&nbsp;</Typography>
        </Box>
    }

    const getPriceText = () => {
        return convertStringToMoney(purchase.price * purchase.quantity);
    }

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const removeItem = (event) => {
        event.stopPropagation();
        decreasePurchase(purchase)
    }

    const [amount, setAmount] = useState(purchase.quantity);

    useEffect(() => {
        setAmount(purchase.quantity);
    })

    const changeAmount = (event) => {
        console.log(event.target.value);
        setPurchase(purchase, event.target.value);
    }

    return (
        // <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ bgcolor: 'orange', height: '48px', padding: '6px', margin: '8px', display: 'flex' }}>
        <Box sx={{ paddingLeft: '12px', paddingRight: '12px', marginBottom: '6px', minWidth: '320px' }}>
            {
                showExpand ?
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Button onClick={removeItem} startIcon={<CloseIcon sx={{ marginRight: '0px', marginLeft: '0px' }}/>} variant="contained" color="error" sx={{ display: showDeleteButton ? 'flex' : 'none', maxWidth: '5px', width: '5px', marginLeft: '-10px', marginRight: '8px', minWidth: '32px', paddingRight: '3px', borderRadius: '32px' }} />
                            <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1' }}>
                                {getQuantityText()}
                                <Typography variant="h6" sx={{ flex: 1, fontWeight: 'bold' }}>{purchase.name}</Typography>
                                <Box sx={{ flex: '1' }}/>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{getPriceText()}</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginBottom: '6px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '4px' }}>
                                    <TextField id="outlined-basic" label="Quantity" variant="outlined" value={amount} onChange={changeAmount} type="number"
                                               InputProps={{ inputProps: { min: 1, max:100 } }}/>
                                </Box>
                                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '4px' }}>
                                    <TextField id="outlined-basic" label="Discount" variant="outlined" />
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion> :
                    <Card sx={{ padding: '16px' }} >
                        <Button onClick={removeItem} startIcon={<CloseIcon sx={{ marginRight: '0px', marginLeft: '0px' }}/>} variant="contained" color="error" sx={{ display: showDeleteButton ? 'flex' : 'none', maxWidth: '5px', width: '5px', marginLeft: '-10px', marginRight: '8px', minWidth: '32px', paddingRight: '3px', borderRadius: '32px' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1' }}>
                            {getQuantityText()}
                            <Typography variant="h6" sx={{ flex: 1, fontWeight: 'bold' }}>{purchase.name}</Typography>
                            <Box sx={{ flex: '1' }}/>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{getPriceText()}</Typography>
                        </Box>
                    </Card>

            }
        </Box>
    )
}