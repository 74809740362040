import axios from 'axios';
import axiosRetry from 'axios-retry';
import userpool from "../auth/Userpool";


const BASE_URL = 'https://8lc7uqjk4j.execute-api.ap-southeast-2.amazonaws.com/dev/configuration'

axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 100; // time interval between retries
    },
});

export function getConfiguration() {
    return axios.get(BASE_URL, {
        headers: {
            'Authorization': `Bearer ${getCurrentUserSessionIdToken()}`
        }
    })
        .then(response => response.data);
}

export function upsertConfiguration(configuration) {
    console.log(configuration);
    const configurationJson = new URLSearchParams(configuration);
    console.log(configurationJson);
    return axios.post(`${BASE_URL}`, {
        configuration: configuration
    }, {
        headers: {
            'Authorization': `Bearer ${getCurrentUserSessionIdToken()}`
        }
    })
        .then(response => response.data);
}

function getCurrentUserSessionIdToken() {
    return userpool.getCurrentUser().getSession((error, session) => {
        return session.getIdToken().getJwtToken();
    });
}