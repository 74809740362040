import {useContext, useEffect, useState} from "react";
import { Waveform } from '@uiball/loaders'

import {listItems} from "./api/ItemApi";
import {Box, Button, Grid, InputBase, Paper, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {AppContext} from "./Application";
import AddItemForm from "./AddItemForm";
import CategoryCard from "./CategoryCard";
import AddCategoryForm from "./AddCategoryForm";
import SearchIcon from '@mui/icons-material/Search';


export default function Categories() {

    const props = useContext(AppContext);
    const items = props.items;
    const categories = props.categories;
    const categoryMap = props.categoryMap;

    const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
    const [search, setSearch] = useState(false);

    const categoryCards = () => {
        let filteredCategories = categories;

        if (!!search) {

            filteredCategories = categories.filter(category => category.name.toLowerCase().includes(search.toLowerCase()));
        }

        return filteredCategories.sort((a, b) => a.name.localeCompare(b.name)).map(category => {
            return <Grid key={category.id} md={3} sm={4} xs={6} item={true}>
                <CategoryCard
                    id={category.id}
                    name={category.name}
                    color={category.color}
                    createdAt={category.createdAt}
                    updatedAt={category.updatedAt}
                    search={search}
                />
            </Grid>
        })
    }

    const onSearchInputChange = (event) => {
        setSearch(event.target.value);
    }

    return (
        <Box>
            <Typography variant="h3" noWrap component="div" sx={{paddingBottom: '16px'}}>
                Categories
                <Button variant="contained" onClick={() => setCreateCategoryOpen(true)} sx={{float: 'right', marginTop: '16px'}}>
                    Add Category
                </Button>
            </Typography>
            <Divider variant="middle" sx={{marginBottom: '32px'}}/>
            <Box>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search categories"
                        onChange={onSearchInputChange}
                    />
                    <SearchIcon />
                </Paper>
                <Grid container>
                    {
                        categoryCards()
                    }
                </Grid>
            </Box>
            <AddCategoryForm open={createCategoryOpen} setOpen={(open) => setCreateCategoryOpen(open)}/>
        </Box>

    )
}