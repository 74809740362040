import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Backdrop, Box, DialogContent, FormControl, InputLabel, Select, TextField} from "@mui/material";
import {Waveform} from "@uiball/loaders";
import ReactCrop from "react-image-crop";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import * as React from 'react';
import Slide from "@mui/material/Slide";
import PurchaseItem from "./PurchaseItem";
import Prices from "./Prices";
import Payment from "./Payment";
import RefreshIcon from '@mui/icons-material/Refresh';
import {useState} from "react";
import PaymentTile from "./PaymentTile";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TransactionDialog({open, setOpen, purchaseList, finishTransaction}) {

    const [payments, setPayments] = useState({});
    const [completeTransaction, setCompleteTransaction] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const buildTransactions = () => {
        return Object.keys(purchaseList).map((key) => {
            console.log(key);
            const obj = purchaseList[key];
            console.log(obj);
            return (
                <div key={obj.itemId} >
                    <PurchaseItem purchase={obj} showDeleteButton={false} showExpand={false} />
                </div>
            )
        })
    }

    const buildPayments = () => {
        return Object.keys(payments).map((key) => {
            console.log(key);
            const obj = payments[key];
            console.log(obj);
            return (
                <div key={obj.id}>
                    <PaymentTile id={obj.id} amount={obj.amount} paymentMethod={obj.paymentMethod} deletePayment={() => deletePayment(obj.id)} showDeletePayment={true}/>
                </div>
            )
        })
    }

    const onClickAdd = (id, paymentMethod, amount) => {
        console.log(paymentMethod);
        console.log(amount);
        const copy = {};
        Object.assign(copy, payments);
        copy[id] = {
            amount,
            paymentMethod,
            id
        }

        setPayments(copy);
    }

    const deletePayment = (id) => {
        const copy = {};
        Object.assign(copy, payments);

        delete copy[id];
        setPayments(copy);
    }

    const onClickCompleteTransaction = () => {
        setCompleteTransaction(true);

    }

    const closeCompleteTransaction = () => {
        setCompleteTransaction(false);
    }

    const handleTransaction = () => {
        closeCompleteTransaction();
        finishTransaction(payments, setLoading);
        resetTransaction();
    }

    const resetTransaction = () => {
        setPayments({});
    }

    return (
        <div style={{ backgroundColor: '#efefef' }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => 99999 }}
                open={loading}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Waveform
                            size={80}
                            lineWeight={3.5}
                            speed={1}
                            color="white"
                        />
                    </Box>
                    <p>Completing transaction...</p>
                </Box>
            </Backdrop>
            <Dialog
                open={completeTransaction}
                onClose={closeCompleteTransaction}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Complete transaction
                </DialogTitle>
                <DialogContent>
                    Would you like to complete this transaction?
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCompleteTransaction}>Cancel</Button>
                    <Button onClick={handleTransaction}>
                        Complete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                sx={{ backgroundColor: '#efefef' }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Payment
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', backgroundColor: '#efefef' }}>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '16px' }} >
                        <Typography variant="h6" sx={{ paddingBottom: '16px' }}>Summary of Basket</Typography>
                        <Box sx={{ overflow: 'auto', paddingBottom: '32px', height: '240px'}}>
                            {
                                buildTransactions()
                            }
                        </Box>
                        <Typography variant="h6" sx={{ paddingBottom: '16px'}}>Payments</Typography>
                        <Box sx={{ overflow: 'auto', height: '240px'}}>
                            {
                                buildPayments()
                            }
                        </Box>
                        <Box sx={{ flex: 1 }}/>
                        <Box sx={{ flex: 1 }}>
                            <Prices purchaseList={purchaseList} payments={payments} countPayments={true}/>
                        </Box>
                    </Box>

                    <Box sx={{ bgColor: 'blue', flex: 3, margin: '16px' }}>
                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ height: '100%', flex: '1' }}>
                                <Payment onClickAdd={onClickAdd} deletePayment={deletePayment} onClickCompleteTransaction={onClickCompleteTransaction} isDisabled={Object.keys(payments).length === 0}/>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}