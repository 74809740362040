import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';

const LinkBehavior = ((props, ref) => {
    const { href, ...other } = props;
    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

// A custom theme for this app
const theme = createTheme({
    components: {
        MuiTypography: {
            defaultProps: {
                fontWeight: '500'
            }
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold'
                }
            }
        },
        MuiExpansionPanelSummary: {
            styleOverrides: {
                root: {
                    // this works
                    '&.Mui-expanded': {
                        marginBottom: '6px',
                    },
                },
            },
        },
    },

    typography: {
        fontFamily: 'Satoshi-Regular',
    },
    palette: {
        // Default dark mode theme
        // primary: {
        //     main: '#556cd6',
        // },
        // secondary: {
        //     main: '#19857b',
        // },
        // error: {
        //     main: red.A400,
        // },
        // mode: 'dark',

        background: {
            default: '#efefef'
        },
        primary: {
            main: '#132043',
            dark: '#132043'
        },
        secondary: {
            main: '#1F4172',
            dark: '#132043'
        },
        error: {
            main: red.A400,
        },
        mode: 'light'
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
        easing: {
            // This is the most common easing curve.
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            // Objects enter the screen at full velocity from off-screen and
            // slowly decelerate to a resting point.
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            // Objects leave the screen at full velocity. They do not decelerate when off-screen.
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            // The sharp curve is used by objects that may return to the screen at any time.
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
    },
});

export default theme;