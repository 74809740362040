import {useContext, useEffect, useRef, useState} from "react";
import { Waveform } from '@uiball/loaders'

import {Box, Button, Grid, InputBase, InputLabel, MenuItem, Paper, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {AppContext} from "./Application";
import ItemCard from "./ItemCard";
import AddItemForm from "./AddItemForm";
import SearchIcon from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import {getItemImage} from "./api/ItemApi";
import Select from '@mui/material/Select';
import {useTriggerScrollFix} from "./utils/Utils";

export default function Items() {

    const props = useContext(AppContext);
    const configuration = props.configuration;
    const items = props.items;
    let currentItems = props.items;
    const categoryMap = props.categoryMap;
    const itemSkuMap = props.itemSkuMap;

    const [createItemOpen, setCreateItemOpen] = useState(false);
    const [search, setSearch] = useState("");
    const searchRef = useRef("");
    const [infiniteScrollItems, setInfiniteScrollItems] = useState([]);
    const ITEM_COUNT = 20;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [searchItemPlaceholder, setSearchItemPlaceholder] = useState("Search items by name");

    useEffect(() => {
        refreshItems();
    }, [items]);

    const refreshItems = () => {
        console.log("RERUNNING USEEFFECT")
        const slicedItems = items.slice(0, ITEM_COUNT);

        async function sliceItems(search) {
            for (const item of slicedItems) {
                item.image = await getItemImage(item.imagePath);
            }

            setInfiniteScrollItems(slicedItems);
            setCurrentIndex(slicedItems.length);
            console.log(`Completed items for search term ${search}`)
        }

        sliceItems(search);
    }

    useEffect(() => {
        fetchData()
    }, [search])

    const itemCards = () => {
        console.log("RELOADING THIS!")
        console.log(infiniteScrollItems);
        let filteredItems = infiniteScrollItems;

        // if (!!search) {
        //     console.log(search);
        //     filteredItems = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
        //     // currentItems = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
        // }

        return filteredItems.map(item => {
            const category = categoryMap[item.categoryId];

            // <Grid key={item.itemId} md={3} sm={4} xs={6} item={true}>
            return <div key={item.itemId}>
                <ItemCard
                    id={item.itemId}
                    name={item.name}
                    categoryId={item.categoryId}
                    categoryName={category.name}
                    categoryColor={category.color}
                    sku={item.sku}
                    quantity={item.quantity}
                    cost={item.cost}
                    price={item.price}
                    markup={item.markup}
                    imagePath={item.imagePath}
                    image={item.image}
                    createdAt={item.createdAt}
                    updatedAt={item.updatedAt}
                    search={search}

                />
            </div>
        })
    }

    const onSearchInputChange = (event) => {
        console.log(event.target.value);
        setSearch(event.target.value);
        searchRef.current = event.target.value;
        setInfiniteScrollItems([]);
        setCurrentIndex(0);
    }

    const fetchData = (event) => {
        const slicedItems = [];

        let count = 0;
        let i = currentIndex;
        while (i < items.length && count < ITEM_COUNT) {
            const item = items[i];
            if (!search) {
                slicedItems.push(item);
            } else {
                if (item.sku.toLowerCase() === search.toLowerCase()) {
                    slicedItems.unshift(item);
                } else if (item.name.toLowerCase() === search.toLowerCase()) {
                    // If first item is an SKU match, push to second position
                    if (slicedItems.length > 0 && slicedItems[0].sku.toLowerCase() === search.toLowerCase()) {
                        slicedItems.splice(1, 0, item);
                    } else {
                        slicedItems.unshift(item);
                    }
                } else if (item.name.toLowerCase().includes(search.toLowerCase())) {
                    slicedItems.push(item);
                }
                count++;
            }
            i++;
        }

        setCurrentIndex(i);

        async function sliceItems(currentSearch) {

            console.log(`slicedItems length: ${slicedItems.length}`)
            if (slicedItems.length === 0) {
                console.log("EMPTY!");
                setInfiniteScrollItems([]);
                return;
            }

            for (const item of slicedItems) {
                if (!!item.image) {
                    continue;
                }
                item.image = await getItemImage(item.imagePath);
            }

            if (currentSearch !== searchRef.current) {
                console.log(`Search term has changed. Expected term: ${currentSearch} New Search: ${searchRef.current}`);
                return;
            }
            setInfiniteScrollItems([...infiniteScrollItems, ...slicedItems]);

            console.log(`infiniteScrollItems length: ${infiniteScrollItems.length}`);
            console.log(`slicedItems length: ${slicedItems.length}`);

            // setCurrentIndex(currentIndex + slicedItems.length);
            console.log(`Completed items for search term ${search}`)
        }

        sliceItems(search);
    }

    const getHasMore = () => {
        let length = items.length;
        if (!!search) {
            length = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).length + items.filter(item => item.sku.toLowerCase() === search.toLowerCase()).length;
        }

        console.log(`HASMORE: ${length > infiniteScrollItems.length}`)

        return length > infiniteScrollItems.length;
    }

    useTriggerScrollFix([items.length]);

    const checkKeyPress = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            console.log("ENTER CLICKED!");
            // onLoginClick();
        }
    }

    return (
        <Box sx={{ height: '90%' }}>
            <Typography variant="h3" noWrap component="div" sx={{paddingBottom: '16px'}}>
                Items
                <Button variant="contained" onClick={() => setCreateItemOpen(true)} sx={{float: 'right', marginTop: '16px'}}>
                    Add Item
                </Button>
            </Typography>
            <Divider variant="middle" sx={{marginBottom: '32px'}}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', height: '85%' }}>
                <Box sx={{ flex: '2', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '40px' }}>
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', flex: 1 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={searchItemPlaceholder}
                                onChange={onSearchInputChange}
                                value={search}
                                onKeyDown={checkKeyPress}
                            />
                            <SearchIcon/>
                        </Paper>
                    </Box>

                    <Box id="scrollableBox" sx={{ height: '100%', flex: 1, marginTop: '24px', marginRight: '12px', overflow: 'auto' }}>
                        <InfiniteScroll
                            dataLength={infiniteScrollItems.length}
                            next={fetchData}
                            hasMore={getHasMore()}
                            loader={<p style={{ fontSize: '20px', textAlign: 'center' }}><b>Loading...</b></p>}
                            data-testid="infinite-scroll"
                            style={{ padding: '5px' }}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                            scrollableTarget="scrollableBox"
                        >
                            <Grid container>
                                {itemCards()}
                            </Grid>
                        </InfiniteScroll>
                    </Box>
                </Box>


                {/*<Box id="scrollableBox" sx={{*/}
                {/*    overflow: 'auto',*/}
                {/*    display: 'flex',*/}
                {/*    flexDirection: 'column-reverse',*/}
                {/*}}>*/}
                {/*</Box>*/}


            </Box>
            <AddItemForm open={createItemOpen} setOpen={setCreateItemOpen}/>
        </Box>

    )
}