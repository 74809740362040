import {Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import CategoryDialog from "./CategoryDialog";
import {useState} from "react";


export default function CategoryCard({id, name, color, createdAt, updatedAt, search}) {

    const [openForm, setOpenForm] = useState(false);

    const onClickCard = () => {
        setOpenForm(true);
    }

    const handleClose = () => {
        setOpenForm(false);
    }

    const getHighlightedText = (text, highlight) => {
        // Split text on highlight term, include term itself into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <mark>{part}</mark> : part)}</span>;
    }

    return (
        <Box>
            <Card sx={{margin: '16px'}} onClick={() => onClickCard()}>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" sx={{ color: color }}>
                            {!!search ? getHighlightedText(name, search) : name}
                        </Typography>
                    </CardContent>
                </CardActionArea>

            </Card>
            <CategoryDialog handleClose={() => handleClose()} open={openForm} id={id} name={name} color={color}/>
        </Box>
    )
}