import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import {Alert, Box, Button, styled, TextField, Toolbar, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {authenticate} from "./auth/Authenticate";
import {Auth} from "aws-amplify";

export default function Login({setUserSession}) {

    const drawerWidth = 240;

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            // width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const [username, setUsername] = useState('');
    const [usernameHelpText, setUsernameHelpText] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordHelpText, setPasswordHelpText] = useState('');
    const [passwordError, setPasswordError] = useState(false);

    const [loginFailed, setLoginFailed] = useState(false);

    const onUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const onLoginClick = () => {
        setLoginFailed(false);
        let validRequest = true;

        if (!username) {
            setUsernameError(true);
            setUsernameHelpText('Username is required');
            validRequest = false;
        } else {
            setUsernameError(false);
            setUsernameHelpText('');
        }

        if (!password) {
            setPasswordError(true);
            setPasswordHelpText('Password is required');
            validRequest = false;
        } else {
            setPasswordError(false);
            setPasswordHelpText('');
        }

        if (validRequest) {
            console.log("Valid login request");
            Auth.signIn(username, password).then(data => {
                console.log(data);
                setLoginFailed(false);
                setUserSession(data)
            }, error => {
                console.log("Login failed", error);
                setLoginFailed(true);
            });
            // authenticate(username, password)
            //     .then((data) => {
            //         setLoginFailed(false);
            //         setUserSession(data);
            //     }, (error) => {
            //         console.log("Login failed", error);
            //         setLoginFailed(true);
            //     })
        }
    }

    const checkKeyPress = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            onLoginClick();
        }
    }

    return(
        <Box sx={{ display: 'flex', width:'100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        forefront
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box component="main" sx={{ display: 'flex', border: '1px solid grey', padding: '24px', flexDirection: 'column', minWidth: '290px' }}>
                <Typography variant="h6" noWrap component="div" sx={{ paddingBottom: '16px' }}>Login</Typography>

                {
                    loginFailed && <Alert severity="error" sx={{ marginBottom: '16px', maxWidth: '240px' }}>Login failed. Please ensure you have the correct username and password.</Alert>
                }

                <TextField onKeyDown={checkKeyPress} label="Username (or Email)" variant="outlined" value={username} onChange={event => onUsernameChange(event)} sx={{ paddingBottom: '16px' }} helperText={usernameHelpText} error={usernameError}/>
                <TextField onKeyDown={checkKeyPress} label="Password" variant="outlined" type="password" value={password} onChange={event => onPasswordChange(event)} sx={{ paddingBottom: '16px'}} helperText={passwordHelpText} error={passwordError}/>

                <Button onClick={() => onLoginClick()} variant="contained">Login</Button>
            </Box>
        </Box>
    )
}