import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Box, FormControl, InputAdornment, InputLabel, Select} from "@mui/material";
import TextField from "@mui/material/TextField";
import {NumericFormat} from "react-number-format";
import ReactCrop from "react-image-crop";
import Button from "@mui/material/Button";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Prices from "./Prices";
import Payment from "./Payment";
import * as React from "react";
import PurchaseItem from "./PurchaseItem";
import PaymentTile from "./PaymentTile";


export default function SalesHistoryDialog({open, handleClose, transaction}) {

    const buildTransactions = () => {
        return transaction.items.map((item) => {
            console.log(item);
            return (
                <div key={item.id} >
                    <PurchaseItem purchase={item} showDeleteButton={false} showExpand={false} />
                </div>
            )
        })
    }

    const buildPayments = () => {
        return transaction.payments.map((payment) => {
            console.log(payment);
            return (
                <div key={payment.id}>
                    <PaymentTile amount={payment.amount} paymentMethod={payment.paymentMethod} />
                </div>
            )
        })
    }


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent sx={{ backgroundColor: '#efefef' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '16px' }} >
                        <Typography variant="h6" sx={{ paddingBottom: '16px' }}>Summary of Basket</Typography>
                        <Box sx={{ overflow: 'auto', paddingBottom: '32px', height: '240px'}}>
                            {
                                buildTransactions()
                            }
                        </Box>
                        <Typography variant="h6" sx={{ paddingBottom: '16px'}}>Payments</Typography>
                        <Box sx={{ overflow: 'auto', height: '240px'}}>
                            {
                                buildPayments()
                            }
                        </Box>
                        <Box sx={{ flex: 1 }}/>
                        <Box sx={{ flex: 1 }}>
                            <Prices purchaseList={transaction.items} payments={transaction.payments} countPayments={false}/>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: '#efefef' }}>
                <div style={{flex: '1 0 0'}} />
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}