import {
    Box,
    Button,
    Grid,
    InputBase,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TablePagination, TableRow,
    Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import AddItemForm from "./AddItemForm";
import {useContext, useState} from "react";
import {AppContext} from "./Application";
import {convertStringToMoney, convertToCurrency} from "./utils/Utils";
import IconButton from "@mui/material/IconButton";
import SalesHistoryDialog from "./SalesHistoryDialog";

const columns = [
    { id: 'search', label: '', width: 10, format: (value) => value },
    { id: 'date', label: 'Date', minWidth: 170, format: (value) => new Date(value * 1000).toLocaleString() },
    { id: 'totalPrice', label: 'Total Price', minWidth: 170, format: value => convertStringToMoney(value) },
    { id: 'totalReceived', label: 'Total Received', minWidth: 170, format: value => convertStringToMoney(value) },
];

export default function SalesHistory() {

    const props = useContext(AppContext);
    const transactions = props.transactions;
    const transactionMap = props.transactionMap;

    console.log(transactions);
    console.log(transactionMap);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = () => {
        return transactions.transactions.map(transaction => {
            return {
                id: transaction.transactionId,
                date: transaction.createdAt,
                totalPrice: calculateTotalPrice(transaction.items, transaction.discount),
                totalReceived: calculateTotalReceived(transaction.payments)
            }
        })
    }

    const calculateTotalPrice = (items, discount) => {
        let total = 0;
        items.forEach(item => {
            console.log(item);
            let amount = item.price;

            if (!!item.discount) {
                amount -= item.discount;
            }
            total += amount * item.quantity;
        })

        console.log(total);
        total -= discount;

        total = total + total * 0.15

        console.log(total);
        return convertToCurrency(total);
    }

    const calculateTotalReceived = (payments) => {
        let total = 0;
        payments.forEach(payment => {
            console.log(payment.amount);
            total += +payment.amount;
        })

        console.log(total);
        return convertToCurrency(total);
    }

    const openTransaction = (row) => {
        console.log("AAAAAAAAAA");
        console.log(row);
        console.log(transactionMap[row.id]);
        setSelectedTransaction(transactionMap[row.id]);
        setOpen(true);
    }

    return (
        <Box sx={{ height: '80%' }}>
            {selectedTransaction && <SalesHistoryDialog open={open} handleClose={() => setOpen(false)} transaction={selectedTransaction}/>}
            <Typography variant="h3" noWrap component="div" sx={{paddingBottom: '16px'}}>
                Sales History
            </Typography>
            <Divider variant="middle" sx={{marginBottom: '32px'}}/>
            <Box sx={{ height: '100%' }}>

                <Paper sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ height: '90%' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, width: column.width }}
                                            onClick={() => console.log("CLICK")}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows()
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => openTransaction(row)}
                                                    >
                                                        <SearchIcon/>
                                                    </IconButton>
                                                </TableCell>
                                                {columns.slice(1).map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format(value)}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>

                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={rows().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </Box>
    );
}