import * as React from 'react';
import Login from "./Login";
import {useEffect, useState} from "react";
import Application from "./Application";
import userpool from "./auth/Userpool";
import {authenticate} from "./auth/Authenticate";

export default function App() {

    const [userSession, setUserSession] = useState(null);
    const [ready, setReady] = useState(false);

    useEffect(()=>{
        const currentUser = userpool.getCurrentUser();
        if (currentUser) {
            setUserSession(currentUser);
        }
        setReady(true);
    },[]);

    return (
        ready ? (
            userSession ? <Application userSession={userSession} setUserSession={(userSession) => setUserSession(userSession)}/>
            :  <Login setUserSession={(userSession) => setUserSession(userSession)}/>) :
            <div/>
    );
}