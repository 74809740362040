import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {Backdrop, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useContext, useState} from "react";
import {AppContext} from "./Application";
import {Waveform} from "@uiball/loaders";
import {createItem} from "./api/ItemApi";
import { CirclePicker } from "react-color";
import './css/category-form.css';
import {createCategory} from "./api/CategoryApi";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCategoryForm({open, setOpen}) {
    const handleAdd = () => {
        setLoading(true);

        createCategory(name, selectedColor)
            .then(result => {
                setOpen(false);
                setLoading(false);
                props.loadData();
            });
    };

    const handleClose = () => {
        setOpen(false);
    }

    const props = useContext(AppContext);
    const categoryNameMap = props.categoryNameMap;

    const [loading, setLoading] = useState(false);

    const [categoryNameError, setCategoryNameError] = useState('');

    const [selectedColor, setSelectedColor] = useState('');
    const [name, setName] = useState('');
    const [addButtonEnabled, setAddButtonEnabled] = useState(false);

    const onChangeName = (event) => {
        let state = false;
        const newName = event.target.value;

        if (categoryNameMap[newName]) {
            setCategoryNameError('This category already exists');
        } else {
            setCategoryNameError('');
        }
        if (!categoryNameMap[newName] && newName && selectedColor) {
            state = true;
        }

        setName(newName);
        setAddButtonEnabled(state);
    }

    const onChangeColor = (event) => {
        let state = false;
        const newColor = event;

        if (!categoryNameMap[name] && name && newColor) {
            state = true;
        }

        setSelectedColor(newColor);
        setAddButtonEnabled(state);
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add Category
                        </Typography>
                        <Button disabled={!addButtonEnabled} autoFocus color="inherit" onClick={() => handleAdd()}>
                            Add
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Waveform
                                    size={80}
                                    lineWeight={3.5}
                                    speed={1}
                                    color="white"
                                />
                            </Box>
                            <p>Creating category...</p>
                        </Box>
                    </Backdrop>
                    <FormControl>
                        <TextField onChange={(event) => onChangeName(event)} variant="outlined" label="Name" sx={{ flex: '1', margin: '16px', marginTop: '24px' }} helperText={categoryNameError} error={!!categoryNameError}/>
                        <Box sx={{ flex: '1', marginLeft: '16px' }}>
                            <p>Color</p>
                            <Box sx={{ flexDirection: 'column' }}>
                                <CirclePicker
                                    color={selectedColor}
                                    onChange={(color) => onChangeColor(color.hex)}
                                />
                                <Box sx={{ flex: '1', backgroundColor: 'white' }}/>
                            </Box>

                        </Box>
                    </FormControl>
                </Box>
            </Dialog>
        </div>
    );
}