import {
    Box,
    Button,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput, Slide, StepContent,
    Stepper,
    TextField, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import * as React from 'react';
import {NumericFormat} from "react-number-format";
import {useState} from "react";

const steps = ['Select payment method', 'Enter amount'];

export default function Payment({onClickAdd, onClickCompleteTransaction, isDisabled}) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [amount, setAmount] = useState("0.00");
    const [paymentMethod, setPaymentMethod] = useState("CASH");

    const onChangeAmount = (event) => {
        setAmount(event.value);
    }

    const addClicked =(() => {
        setAmount("0.00");
        setPaymentMethod("CASH");
        onClickAdd(crypto.randomUUID(), paymentMethod, amount);
    })

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onClickBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    // const getStepContent = () => {
    //     if (activeStep === 0) {
    //         return (
    //             <Slide direction="left" in={activeStep === 0}>
    //                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', height: '240px' }}>
    //                     <Button variant="contained" sx={{ flex: 1, margin: '16px', fontSize: '32px'}} onClick={() => onClickPaymentMethod('CASH')} >Cash</Button>
    //                     <Button variant="contained" sx={{ flex: 1, margin: '16px', fontSize: '32px' }} onClick={() => onClickPaymentMethod('CARD')} >Card</Button>
    //                 </Box>
    //             </Slide>
    //         );
    //     } else {
    //         return (
    //             <Slide direction="left" in={activeStep === 1} >
    //                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
    //                     <NumericFormat customInput={TextField}
    //                                    variant="outlined"
    //                                    value={amount}
    //                                    label="Amount"
    //                                    thousandSeparator={true}
    //                                    placeholder={"0.00"}
    //                                    decimalScale={2}
    //                                    allowNegative={false}
    //                                    fixedDecimalScale
    //                                    valueIsNumericString={true}
    //                                    autoComplete="off"
    //                                    InputProps={{
    //                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
    //                                    }}
    //                                    onValueChange={(event) => onChangeAmount(event)}
    //                                    sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
    //                     />
    //                     <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
    //                         <Button sx={{ margin: '8px' }} variant="contained">Submit</Button>
    //                         <Button sx={{ margin: '8px' }} variant="outlined" onClick={() => onClickBack()}>Back</Button>
    //                     </Box>
    //                 </Box>
    //             </Slide>
    //         );
    //     }
    // }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onClickPaymentMethod = (event) => {
        console.log(event.target.value);
        setPaymentMethod(event.target.value);
        setActiveStep(activeStep + 1);
    }

    return (
        <Box sx={{ height: '100%', margin: '16px', display: 'flex', flexDirection: 'column' }}>
             <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, alignItems: 'center' }}>
                 {/*{*/}
                 {/*    getStepContent()*/}
                 {/*}*/}
                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100px'}}>
                     <ToggleButtonGroup
                         color="success"
                         value={paymentMethod}
                         defaultValue={paymentMethod}
                         exclusive
                         onChange={(event) => onClickPaymentMethod(event)}
                         sx={{ width: '100%' }}
                     >
                         <ToggleButton value="CASH" sx={{ width: '50%', fontSize: '32px', fontWeight: 'bold' }}>Cash</ToggleButton>
                         <ToggleButton value="CARD" sx={{ width: '50%', fontSize: '32px', fontWeight: 'bold' }}>Card</ToggleButton>
                     </ToggleButtonGroup>
                     {/*<Button variant="contained" sx={{ flex: 1, margin: '16px', fontSize: '32px'}} onClick={() => onClickPaymentMethod('CASH')} >Cash</Button>*/}
                     {/*<Button variant="contained" sx={{ flex: 1, margin: '16px', fontSize: '32px' }} onClick={() => onClickPaymentMethod('CARD')} >Card</Button>*/}
                 </Box>

                 <Box sx={{ display: 'flex', flexDirection: 'row', flex: '1', margin: '16px', marginTop: '24px'}}>
                     <NumericFormat customInput={TextField}
                                    variant="outlined"
                                    value={amount}
                                    label="Amount"
                                    thousandSeparator={true}
                                    placeholder={"0.00"}
                                    decimalScale={2}
                                    allowNegative={false}
                                    fixedDecimalScale
                                    valueIsNumericString={true}
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    onValueChange={(event) => onChangeAmount(event)}
                     />
                     <Box>
                         <Button sx={{ marginLeft: '16px', height: '100%' }} variant="contained" color="primary" onClick={() => addClicked()}>Add</Button>
                     </Box>
                 </Box>

             </Box>
            <Box sx={{ flex: '1' }}>

            </Box>
            <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column', marginBottom: '32px', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="success" sx={{ height: '50%', fontSize: '2rem' }} onClick={() => onClickCompleteTransaction()} disabled={isDisabled}>Complete transaction</Button>
            </Box>
        </Box>


        // <Box sx={{ margin: '16px' }}>
        //     <Stepper activeStep={activeStep}>
        //         {steps.map((label, index) => {
        //             const stepProps = {};
        //             const labelProps = {};
        //
        //             return (
        //                 <Step key={label} {...stepProps}>
        //                     <StepLabel {...labelProps}>{label}</StepLabel>
        //                 </Step>
        //             );
        //         })}
        //     </Stepper>
        //     {activeStep === steps.length ? (
        //         <React.Fragment>
        //             <Typography sx={{ mt: 2, mb: 1 }}>
        //                 All steps completed - you&apos;re finished
        //             </Typography>
        //             <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        //                 <Box sx={{ flex: '1 1 auto' }} />
        //                 <Button onClick={handleReset}>Reset</Button>
        //             </Box>
        //         </React.Fragment>
        //     ) : (
        //         <React.Fragment>
        //             <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>{steps[activeStep]}</Typography>
        //             <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
        //                 {/*{*/}
        //                 {/*    getStepContent()*/}
        //                 {/*}*/}
        //                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', height: '240px' }}>
        //                     <Button variant="contained" sx={{ flex: 1, margin: '16px', fontSize: '32px'}} onClick={() => onClickPaymentMethod('CASH')} >Cash</Button>
        //                     <Button variant="contained" sx={{ flex: 1, margin: '16px', fontSize: '32px' }} onClick={() => onClickPaymentMethod('CARD')} >Card</Button>
        //                 </Box>
        //
        //                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        //                     <NumericFormat customInput={TextField}
        //                                    variant="outlined"
        //                                    value={amount}
        //                                    label="Amount"
        //                                    thousandSeparator={true}
        //                                    placeholder={"0.00"}
        //                                    decimalScale={2}
        //                                    allowNegative={false}
        //                                    fixedDecimalScale
        //                                    valueIsNumericString={true}
        //                                    autoComplete="off"
        //                                    InputProps={{
        //                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        //                                    }}
        //                                    onValueChange={(event) => onChangeAmount(event)}
        //                                    sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
        //                     />
        //                     <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        //                         <Button sx={{ margin: '8px' }} variant="contained">Submit</Button>
        //                         <Button sx={{ margin: '8px' }} variant="outlined" onClick={() => onClickBack()}>Back</Button>
        //                     </Box>
        //                 </Box>
        //
        //             </Box>
        //         </React.Fragment>
        //     )}
        // </Box>
    );
}