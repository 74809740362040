import axios from 'axios';
import axiosRetry from "axios-retry";
import userpool from "../auth/Userpool";
import {API, Auth, Storage} from "aws-amplify";

const API_NAME = 'ForefrontTransactionApi';

axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 100; // time interval between retries
    },
});

export async function createTransaction(items, payments, discount) {
    return API.post(API_NAME, '', {
        body: {
            items,
            payments,
            discount
        }
    }).then(data => {
        return data;
    });
}

export async function getTransaction(transactionId) {
    return await API.get(API_NAME, `${transactionId}`, null);
}

export async function listTransactions() {
    return await API.get(API_NAME, '', null);
}