import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Amplify, Auth } from 'aws-amplify';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import App from './App';
import theme from './theme';
import POS from "./POS";
import Items from "./Items";
import Categories from "./Categories";
import './index.css';
import SalesHistory from "./SalesHistory";
import Settings from "./Settings";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_SRP_AUTH',
    },
    API: {
        endpoints: [
            {
                name: "ForefrontItemApi",
                endpoint: "https://8lc7uqjk4j.execute-api.ap-southeast-2.amazonaws.com/dev/item",
                custom_header: async () => {
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
            {
                name: "ForefrontCategoryApi",
                endpoint: "https://8lc7uqjk4j.execute-api.ap-southeast-2.amazonaws.com/dev/item",
                custom_header: async () => {
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
            {
                name: "ForefrontTransactionApi",
                endpoint: "https://8lc7uqjk4j.execute-api.ap-southeast-2.amazonaws.com/dev/transaction",
                custom_header: async () => {
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
            {
                name: "ForefrontConfigurationApi",
                endpoint: "https://8lc7uqjk4j.execute-api.ap-southeast-2.amazonaws.com/dev/configuration",
                custom_header: async () => {
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
        ]
    },
    Storage: {
        AWSS3: {
            bucket: 'forefront-backend-staticresourcebucket-1uancbr0ny4a8', //REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
        },
        customPrefix: {
            public: '',
            protected: '',
            private: ''
        },
    }
});


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/",
                element: <POS/>
            },
            {
                path: "item",
                element: <Items/>
            },
            {
                path: "category",
                element: <Categories/>
            },
            {
                path: "sales",
                element: <SalesHistory/>
            },
            {
                path: "settings",
                element: <Settings/>
            }
        ]
    },
]);

root.render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <RouterProvider router={router}/>
    </ThemeProvider>,
);