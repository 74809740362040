import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { NumericFormat } from 'react-number-format';
import {
    Backdrop,
    Box,
    CircularProgress, FilledInput,
    FormControl, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField, Tooltip
} from "@mui/material";
import {useContext, useRef, useState} from "react";
import {AppContext} from "./Application";
import {Waveform} from "@uiball/loaders";
import {createItem} from "./api/ItemApi";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import {canvasPreview} from "./canvasPreview";
import {useDebounceEffect} from "./useDebounceEffect";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AddItemForm({open, setOpen}) {

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const hiddenAnchorRef = useRef(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1)

    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onSubmitCrop() {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            return null;
        }

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size

        const blob = await offscreen.convertToBlob({
            type: 'image/jpeg',
            quality: 0.6
        });

        return await blobToBase64(blob);
    }

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    const handleAdd = () => {
        setLoading(true);

        onSubmitCrop().then(image => {
            createItem(name, categoryNameMap[selectedCategory] ? categoryNameMap[selectedCategory].id : 'UNCATEGORISED', sku, image, quantity, cost, price, markup)
                .then(result => {
                    setOpen(false);
                    setLoading(false);
                    props.loadData();
                    resetStates();
                });
        });
    };

    const handleClose = () => {
        setOpen(false);
    }

    const props = useContext(AppContext);
    const itemNameMap = props.itemNameMap;
    const categoryNameMap = props.categoryNameMap;

    const [loading, setLoading] = useState(false);

    const [itemNameError, setItemNameError] = useState('');

    const [selectedCategory, setSelectedCategory] = useState('');
    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [quantity, setQuantity] = useState("0");
    const [cost, setCost] = useState("0.00");
    const [price, setPrice] = useState("0.00");
    const [markup, setMarkup] = useState("0");
    const [addButtonEnabled, setAddButtonEnabled] = useState(false);

    const onChangeName = (event) => {
        let state = false;
        const newName = event.target.value;

        if (itemNameMap[newName]) {
            setItemNameError('This item already exists');
        } else {
            setItemNameError('');
        }
        if (!itemNameMap[newName] && newName && sku && selectedCategory && !!crop) {
            state = true;
        }

        setName(newName);
        setAddButtonEnabled(state);
    }

    const onChangeCost = (event) => {
        let newCost = event.value;

        if (cost == newCost) {
            return;
        }

        if (!!!newCost || newCost == 0) {

            newCost = "0.00";
        }

        setCost(newCost);
        setPrice((parseFloat((markup / 100) + 1) * newCost).toFixed(2));
    }

    const onChangePrice = (event) => {
        let newPrice = event.value;

        if (price == newPrice) {
            return;
        }

        if (!!!newPrice || newPrice == 0) {

            newPrice = "0.00";
        }

        setPrice(newPrice);
        setMarkup(((cost == 0 ? 0 : (newPrice / cost) - 1) * 100).toFixed(0));
    }

    const onChangeMarkup = (event) => {
        let newMarkup = event.value;

        if (markup == newMarkup) {
            return;
        }

        if (!!!newMarkup) {

            newMarkup = "0";
        }

        setMarkup(newMarkup);
        setPrice((parseFloat((newMarkup / 100) + 1) * cost).toFixed(2));
    }

    const onChangeQuantity = (event) => {
        let newQuantity = event;
        if (!!!newQuantity) {

            newQuantity = "0";
        }

        setQuantity(newQuantity);
    }

    const onSkuChange = (event) => {
        let state = false;
        const newSku = event.target.value;
        if (!itemNameMap[name] && newSku && name && selectedCategory && !!crop) {
            state = true;
        }

        setSku(newSku);
        setAddButtonEnabled(state);
    }

    const categories = () => {
        return props.categories.sort((a, b) => a.name.localeCompare(b.name)).map(category => {
            return <MenuItem
                key={category.id}
                value={category.name}
                sx={{ color: category.color }}
            >
                {category.name}
            </MenuItem>
        });
    }

    const onCategoryChange = (event) => {
        let state = false;
        const newCategory = event.target.value;

        if (!itemNameMap[name] && newCategory && name && sku && !!crop) {
            state = true;
        }

        setSelectedCategory(newCategory);
        setAddButtonEnabled(state);
    }

    const resetStates = () => {
        setImgSrc('');
        setCrop(null);
        setCompletedCrop(null);
        setSelectedCategory('');
    }

    const isAddButtonEnabled = () => {
        if (!name || itemNameMap[name]) {
            return false;
        }

        return !!name && !!sku;
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add Item
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Waveform
                                    size={80}
                                    lineWeight={3.5}
                                    speed={1}
                                    color="white"
                                />
                            </Box>
                            <p>Creating item...</p>
                        </Box>
                    </Backdrop>
                    <FormControl>
                        <TextField onChange={(event) => onChangeName(event)} variant="outlined" label="Name" sx={{ flex: '1', margin: '16px', marginTop: '24px' }} helperText={itemNameError} error={!!itemNameError}/>
                        <Select labelId="category-label" label="Category" displayEmpty value={selectedCategory} onChange={(category) => onCategoryChange(category)} sx={{ flex: '1', margin: '16px' }}>
                            {categories()}
                        </Select>
                        <InputLabel id="category-label" sx={{ marginTop: !itemNameError ? '112px' : '136px', marginLeft: '16px' }}>Category</InputLabel>

                        <NumericFormat customInput={TextField}
                                      variant="outlined"
                                       value={cost}
                                       label="Cost"
                                      thousandSeparator={true}
                                       placeholder={"0.00"}
                                       decimalScale={2}
                                       allowNegative={false}
                                       fixedDecimalScale
                                      // onChange={handleChange}
                                       valueIsNumericString={true}
                                       defaultValue={0}
                                      autoComplete="off"
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                                       onValueChange={(event) => onChangeCost(event)}
                                       // isAllowed={(values) => {
                                       //     const {floatValue} = values;
                                       //     console.log(floatValue);
                                       //     return floatValue >= 0;
                                       // }}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />

                        <NumericFormat customInput={TextField}
                                       variant="outlined"
                                       value={price}
                                       label="Price"
                                       thousandSeparator={true}
                                       decimalScale={2}
                                       allowNegative={false}
                                       fixedDecimalScale
                            // onChange={handleChange}
                                       placeholder={"0.00"}
                                       autoComplete="off"
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                                       onValueChange={(event) => onChangePrice(event)}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />

                        <NumericFormat customInput={TextField}
                                       variant="outlined"
                                       value={markup}
                                       label={

                                           <Tooltip title="Markup is calculated by ((price - cost) / cost) * 100%">
                                               Markup
                                               <IconButton>
                                                    <HelpOutlineIcon />
                                               </IconButton>
                                           </Tooltip>
                                        }
                                       thousandSeparator={true}
                                       decimalScale={0}
                                       allowNegative={true}
                                       fixedDecimalScale
                            // onChange={handleChange}
                                       autoComplete="off"
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                       }}
                                       placeholder={"0"}
                                       onValueChange={(event) => onChangeMarkup(event)}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />

                        <NumericFormat customInput={TextField}
                                       variant="outlined"
                                       value={quantity}
                                       label="Quantity"
                                       thousandSeparator={true}
                                       decimalScale={0}
                                       allowNegative={false}
                                       fixedDecimalScale
                            // onChange={handleChange}
                                       autoComplete="off"
                                       placeholder={"0"}
                                       onChange={(event) => onChangeQuantity(event.value)}
                                       sx={{ flex: '1', margin: '16px', marginTop: '24px' }}
                        />

                        <TextField onChange={(event) => onSkuChange(event)} variant="outlined" label="SKU" sx={{ flex: '1', margin: '16px' }}/>

                        {!!imgSrc && (
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}
                                maxHeight={512}
                                keepSelection={true}
                                locked={true}
                                style={{maxWidth: '90%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}
                        {!!completedCrop && (
                            <>
                                <div style={{display: 'none'}}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        <Button variant="outlined" startIcon={<CameraAltIcon />} sx={{ margin: '16px', width: '200px', alignSelf: 'center' }} type="file" accept="image/*" component="label">
                            Upload Image
                            <VisuallyHiddenInput type="file" accept="image/*" onChange={onSelectFile}/>
                        </Button>

                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Button variant="outlined" color="error" onClick={() => handleClose()} sx={{ margin: '16px', flex: '1' }}>
                                Cancel
                            </Button>
                            <Button disabled={!isAddButtonEnabled()} variant="contained" color="primary" onClick={() => handleAdd()} sx={{ margin: '16px', flex: '1' }}>
                                Add item
                            </Button>
                        </Box>

                    </FormControl>
                </Box>
            </Dialog>
        </div>
    );
}