import {Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import ItemDialogue from "./ItemDialogue";
import {useEffect, useState} from "react";
import {convertStringToMoney, convertToCurrency} from "./utils/Utils";


export default function ItemCard({id, name, categoryId, categoryName, categoryColor, sku, quantity, cost, price, markup, createdAt, updatedAt, imagePath, image, search, onClickOverride}) {

    const [openForm, setOpenForm] = useState(false);
    // const [image, setImage] = useState(null);

    const onClickCard = () => {
        setOpenForm(true);
    }

    const handleClose = () => {
        setOpenForm(false);
    }

    const getHighlightedText = (text, highlight) => {
        // Split text on highlight term, include term itself into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <mark>{part}</mark> : part)}</span>;
    }

    return (
        <Box>
            <Card sx={{margin: '16px', width: '180px'}}>
                <CardActionArea onClick={() => onClickOverride ? onClickOverride() : onClickCard()}>
                    <CardMedia
                        component="img"
                        height="100"
                        image={image}
                    />
                    <CardContent>
                        <Typography sx={{ fontSize: 8, color: categoryColor }} color="text.secondary" gutterBottom>
                            {categoryName}
                        </Typography>
                        <Box>
                            <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: '0.8rem' }}>
                                {!!search ? getHighlightedText(name, search) : name}
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>{convertStringToMoney(price)}</Typography>
                        </Box>

                    </CardContent>
                </CardActionArea>
            </Card>

            <ItemDialogue handleClose={() => handleClose()} open={openForm} id={id} name={name} sku={sku} quantity={quantity} cost={cost} price={price} markup={markup} categoryName={categoryName} categoryColor={categoryColor}/>
        </Box>
    )
}