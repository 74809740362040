import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Alert,
    Backdrop,
    Box,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select, Snackbar,
    styled,
    Typography
} from "@mui/material";
import {useContext, useEffect} from "react";
import {AppContext} from "./Application";
import {useState} from "react";
import {Waveform} from "@uiball/loaders";
import {CirclePicker} from "react-color";
import {deleteCategory, updateCategory} from "./api/CategoryApi";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import SearchIcon from '@mui/icons-material/Search';
import './css/POS.css';
import InfiniteScroll from "react-infinite-scroll-component";
import {getItemImage} from "./api/ItemApi";
import ItemCard from "./ItemCard";
import PurchaseItem from "./PurchaseItem";
import {convertToCurrency} from "./utils/Utils";
import TransactionDialog from "./TransactionDialog";
import Prices from "./Prices";
import {createTransaction} from "./api/TransactionApi";
import {useRef} from "react";

export default function POS() {
    const props = useContext(AppContext);
    const configuration = props.configuration;
    const items = props.items;
    const categoryMap = props.categoryMap;
    const itemSkuMap = props.itemSkuMap;
    const setAlertOpen = props.setAlertOpen;
    const setAlertMessage = props.setAlertMessage;

    const [createCategoryOpen, setCreateCategoryOpen] = useState(false);

    const [createItemOpen, setCreateItemOpen] = useState(false);
    const [search, setSearch] = useState("");
    const searchRef = useRef("");
    const [infiniteScrollItems, setInfiniteScrollItems] = useState([]);
    const ITEM_COUNT = 10;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [purchaseList, setPurchaseList] = useState({});
    const [open, setOpen] = useState(false);
    const [searchItemPlaceholder, setSearchItemPlaceholder] = useState("Search items by name");

    useEffect(() => {
        refreshItems();
    }, [items]);

    const refreshItems = () => {
        console.log("RERUNNING USEEFFECT")
        const slicedItems = items.slice(0, ITEM_COUNT);

        async function sliceItems(search) {
            for (const item of slicedItems) {
                item.image = await getItemImage(item.imagePath);
            }

            setInfiniteScrollItems(slicedItems);
            setCurrentIndex(slicedItems.length);
            console.log(`Completed items for search term ${search}`)
        }

        sliceItems(search);
    }

    useEffect(() => {
        fetchData()
    }, [search])

    const onClickCard = (item) => {

        const copy = {};
        Object.assign(copy, purchaseList);

        if (copy[item.itemId]) {
            copy[item.itemId].quantity++;
        } else {
            copy[item.itemId] = {
                ...item,
                quantity: 1,
                price: item.price
            }
        }

        setPurchaseList(copy);

        // const purchases = [...purchaseList];
        //
        // if (purchases[item.itemId]) {
        //     purchases[item.itemId].quantity++;
        // } else {
        //     purchases[item.itemId] = {
        //         ...item,
        //         quantity: 1
        //     }
        // }
        //
        // console.log(purchases);
        // setPurchaseList(purchases);
        // console.log(purchaseList);
    }

    const itemCards = () => {
        let filteredItems = infiniteScrollItems;

        // if (!!search) {
        //     console.log(search);
        //     filteredItems = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
        //     // currentItems = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
        // }

        return filteredItems.map(item => {
            const category = categoryMap[item.categoryId];

            // <Grid key={item.itemId} md={3} sm={4} xs={6} item={true}>
            return <div key={item.itemId}>
                <ItemCard
                    id={item.itemId}
                    name={item.name}
                    categoryId={item.categoryId}
                    categoryName={category.name}
                    categoryColor={category.color}
                    sku={item.sku}
                    imagePath={item.imagePath}
                    image={item.image}
                    cost={item.cost}
                    price={item.price}
                    markup={item.markup}
                    createdAt={item.createdAt}
                    updatedAt={item.updatedAt}
                    search={search}
                    onClickOverride={() => onClickCard(item)}
                />
            </div>
        })
    }

    const onSearchInputChange = (event) => {
        setSearch(event.target.value);
        searchRef.current = event.target.value;
        setInfiniteScrollItems([]);
        setCurrentIndex(0);
    }

    const fetchData = (event) => {
        const slicedItems = [];

        let count = 0;
        let i = currentIndex;
        while (i < items.length && count < ITEM_COUNT) {
            const item = items[i];
            if (!search) {
                slicedItems.push(item);
            } else {
                if (item.sku.toLowerCase() === search.toLowerCase()) {
                    slicedItems.unshift(item);
                } else if (item.name.toLowerCase() === search.toLowerCase()) {
                    // If first item is an SKU match, push to second position
                    if (slicedItems.length > 0 && slicedItems[0].sku.toLowerCase() === search.toLowerCase()) {
                        slicedItems.splice(1, 0, item);
                    } else {
                        slicedItems.unshift(item);
                    }
                } else if (item.name.toLowerCase().includes(search.toLowerCase())) {
                    slicedItems.push(item);
                }
                count++;
            }
            i++;
        }

        setCurrentIndex(i);

        async function sliceItems(currentSearch) {

            if (slicedItems.length === 0) {
                console.log("EMPTY!");
                setInfiniteScrollItems([]);
                return;
            }

            for (const item of slicedItems) {
                if (!!item.image) {
                    continue;
                }
                item.image = await getItemImage(item.imagePath);
            }

            console.log(infiniteScrollItems)
            console.log(slicedItems)

            if (currentSearch !== searchRef.current) {
                console.log(`Search term has changed. Expected term: ${currentSearch} New Search: ${searchRef.current}`);
                return;
            }

            setInfiniteScrollItems([...infiniteScrollItems, ...slicedItems]);
            // setCurrentIndex(currentIndex + slicedItems.length);

            console.log(`Completed items for search term ${search}`)
        }

        sliceItems(search);
    }

    const getHasMore = () => {
        let length = items.length;
        if (!!search) {
            length = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).length + items.filter(item => item.sku.toLowerCase() === search.toLowerCase()).length;
        }

        return length > infiniteScrollItems.length;
    }

    const buildPurchases = () => {
        console.log("BUILDING SHIT");
        console.log(purchaseList);
        return Object.keys(purchaseList).map((key) => {
            console.log(key);
            const obj = purchaseList[key];
            console.log(obj);
            return (
                <div key={obj.itemId} >
                    <PurchaseItem purchase={obj} decreasePurchase={decreasePurchase} setPurchase={(purchase, amount) => setPurchase(purchase, amount)} showDeleteButton={true} showExpand={true} />
                </div>
            )
        })
    }

    const decreasePurchase = (purchase) => {
        const copy = {};
        Object.assign(copy, purchaseList);

        copy[purchase.itemId].quantity--;

        if (copy[purchase.itemId].quantity <= 0) {
            delete copy[purchase.itemId];
        }

        setPurchaseList(copy);
    }

    const setPurchase = (purchase, amount) => {
        const copy = {};
        Object.assign(copy, purchaseList);

        copy[purchase.itemId].quantity = amount
        console.log(copy[purchase.itemId].quantity);

        if (copy[purchase.itemId].quantity <= 0) {
            delete copy[purchase.itemId];
        }

        setPurchaseList(copy);
    }

    const completeTransaction = (payments, setLoading) => {
        setLoading(true);

        console.log("AAAAAAAAAAAAA");
        console.log(payments);
        console.log(purchaseList);

        const requestPayments = [];
        const requestItems = [];

        for (const id of Object.keys(payments)) {
            const payment = payments[id];
            console.log(payments);
            console.log(Object.keys(payments));
            console.log(payments[id]);
            requestPayments.push({
                paymentMethod: payment.paymentMethod,
                amount: payment.amount
            });
        }

        for (const id of Object.keys(purchaseList)) {
            const purchase = purchaseList[id];
            requestItems.push({
                id: purchase.itemId,
                name: purchase.name,
                quantity: purchase.quantity,
                price: purchase.price,
                discount: purchase.discount
            });
        }

        createTransaction(requestItems, requestPayments, "0.00")
            .then(data => {
                console.log(data);
                props.loadData();
                setLoading(false);
                setOpen(false);
                setAlertMessage("Transaction complete!");
                setAlertOpen(true);
                reset();
            })
    }

    const reset = () => {
        setPurchaseList({});
    }

    const checkKeyPress = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            console.log("ENTER CLICKED!");

            if (!search) {
                console.log("No search. Skipping.")
                return;
            }

            onClickCard(infiniteScrollItems[0]);
            // onLoginClick();
        }
    }

    return (
        <Box sx={{ height: '90%' }}>
            <TransactionDialog open={open} setOpen={setOpen} purchaseList={purchaseList} finishTransaction={completeTransaction} />
            <Typography variant="h3" noWrap component="div" sx={{paddingBottom: '16px'}}>
                POS
            </Typography>
            <Divider variant="middle" sx={{ marginBottom: '32px' }}/>

            {/*<Grid*/}
            {/*    container*/}
            {/*    direction="row"*/}
            {/*    spacing={0}*/}
            {/*    alignItems="stretch"*/}
            {/*    sx={{  }}*/}
            {/*>*/}
            {/*    <Grid item xs={8} sx={{ flexDirection: 'column' }}>*/}
            {/*        <Paper*/}
            {/*            component="form"*/}
            {/*            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}*/}
            {/*        >*/}
            {/*            <InputBase*/}
            {/*                sx={{ ml: 1, flex: 1 }}*/}
            {/*                placeholder="Search items"*/}
            {/*                onChange={onSearchInputChange}*/}
            {/*            />*/}
            {/*            <SearchIcon />*/}
            {/*        </Paper>*/}
            {/*    </Grid>*/}



            {/*    <Grid container item xs={4} direction="column" alignItems="stretch" sx={{ backgroundColor: 'red' }}>*/}
            {/*        <Box>*/}
            {/*            meow*/}
            {/*        </Box>*/}

            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', height: '85%' }}>
                <Box sx={{ flex: '2', padding: '0px 24px 24px 24px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: '40px' }}>
                            <Paper
                                component="form"
                                sx={{ p: '4px 8px', display: 'flex', alignItems: 'center', flex: 1 }}
                            >
                                <InputBase
                                    autoFocus={true}
                                    sx={{ flex: 1 }}
                                    placeholder="Search items"
                                    onChange={onSearchInputChange}
                                    onKeyDown={checkKeyPress}
                                />
                                <SearchIcon />
                            </Paper>
                        </Box>
                    </Box>

                    <Box sx={{ flex: 1, marginTop: '24px', overflow: 'auto' }} id="scrollableBox">
                        <InfiniteScroll
                            dataLength={infiniteScrollItems.length}
                            next={fetchData}
                            hasMore={getHasMore()}
                            loader={<p style={{ fontSize: '20px', textAlign: 'center' }}><b>Loading...</b></p>}
                            data-testid="infinite-scroll"
                            scrollableTarget="scrollbar-target"
                            style={{ padding: '5px' }}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                            scrollableTarget="scrollableBox"
                        >
                            <Grid container>
                                {itemCards()}
                            </Grid>
                        </InfiniteScroll>
                    </Box>

                </Box>
                <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column', minWidth: '320px', borderStyle: 'solid', borderRadius: '4px', borderColor: '#909cad', borderWidth: '2px', paddingTop: '12px' }}>
                        <Box sx={{ flex: 2, overflow: 'auto' }}>
                            {
                                buildPurchases()
                            }
                        </Box>
                        <Divider variant="middle" sx={{marginTop: '16px'}}/>
                        <Box sx={{ padding: '12px', flex: '1', display: 'flex', flexDirection: 'column' }}>
                            <Prices purchaseList={purchaseList} countPayments={false} />
                            <Button variant="contained" onClick={() => setOpen(true)} disabled={Object.keys(purchaseList).length <= 0}>Proceed to Payment</Button>
                        </Box>

                </Box>
            </Box>
        </Box>
    );
}